import React from 'react';
import SEO from '../components/seo';
import Sticky from 'react-stickynode';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/styles/saas.style';
import Navbar from '../containers/Navbar';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import ThankYouSection from '../containers/ThankYouSection';

const ThankYouPage = () => (
  <>
    <SEO title="Dziękujemy" />
    <ResetCSS />
    <GlobalStyle />
    <ContentWrapper>
      <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
        <DrawerProvider>
          <Navbar returnHome={true} />
        </DrawerProvider>
      </Sticky>
      <ThankYouSection />
    </ContentWrapper>
  </>
);

export default ThankYouPage;
