import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Icon from 'react-icons-kit';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Button from 'common/src/components/Button';
import Checkmark from '../../../../static/img/checkmark.svg';
import { home } from 'react-icons-kit/entypo/home';
import {
  ThankYouWrapper,
  ThankYouContainer,
  ButtonWrapper,
} from './styles/thankYou.style';

const ThankYouSection = ({ imageWrapper, title, text, homeButton }) => {
  return (
    <ThankYouWrapper>
      <ThankYouContainer>
        <Box {...imageWrapper} className="image-wrapper">
          <Image src={Checkmark} alt="Checkmark" />
        </Box>
        <Heading {...title} content="Dziękujemy za wypełnienie formularza" />
        <Text
          {...text}
          content="Skontaktujemy się najszybciej jak to możliwe."
        />
        <ButtonWrapper>
          <Link href="/">
            <Button
              {...homeButton}
              title="Strona główna"
              icon={<Icon icon={home} size={24} />}
              className="domain_search_button"
            />
          </Link>
        </ButtonWrapper>
      </ThankYouContainer>
    </ThankYouWrapper>
  );
};

ThankYouSection.propTypes = {
  imageWrapper: PropTypes.object,
};

ThankYouSection.defaultProps = {
  imageWrapper: {
    mb: ['40px', '55px'],
  },
  title: {
    fontSize: ['26px', '32px', '38px', '48px'],
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '35px'],
    lineHeight: '1.31',
    textAlign: 'center',
    fontWeight: '600',
  },
  text: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#343d48',
    lineHeight: '2',
    mb: ['30px', '40px', '50px', '60px'],
    textAlign: 'center',
  },
  homeButton: {
    pl: ['15px', '22px'],
    pr: ['15px', '22px'],
    iconPosition: 'left',
  },
};

export default ThankYouSection;
