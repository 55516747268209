import styled from 'styled-components';

export const ThankYouWrapper = styled.div`
  padding: 80px 15px;
  min-height: 100vh;
  display: flex;
  align-items: center;
`;

export const ThankYouContainer = styled.div`
  width: 600px;
  max-width: 100%;
  margin: 0 auto;

  .image-wrapper {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
    @media (max-height: 850px) {
      width: 40%;
    }
    @media (max-height: 750px) {
      width: 30%;
    }
  }
`;

export const ButtonWrapper = styled.div`
  font-family: 'roboto';
  text-align: center;
`;
